@import "../../scss/settings";

.dashboard-container {
  display: flex;
  flex-direction: column;
  margin: 0 15px;
  height: 100vh;
  
  .dashboard-title {
    font-weight: $bold;
    font-size: 1.7rem;
    text-align: center;
  }

  .dashboard-subtitle {
    text-align: center;
    font-size: 1.2rem;
    margin-top: 0;
  }

  .dashboard-get-started-button {
    width: 150px;
    margin: 30px auto;
    min-height: 50px;
    max-height: 60px;
  }
}