@import '../../scss/settings';

.error-page {
  display: grid;
  grid-template-rows: 100px $spacing-x10 1fr 1fr;
  justify-items: center;
  margin-top: 60px;

  .error-page__row--top {
    font-size: 5em;
    font-weight: bold;
  }
  .error-page__row--second {
    font-size: 4em;
  }

  .error-page__row--bottom {
    font-size: 1.5em;
    text-align: center;
  }
  .error-page__link {
    text-decoration: none;
    a:visited {
      text-decoration: none;
    }
  }
}


